@font-face {
    font-family: 'Jalnan';
    src: url('assets/fonts/JalnanOTF.otf') format('opentype');
    src: url('assets/fonts/Jalnan.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    src: url('assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans KR';
    src: url('assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans KR Black';
    src: url('assets/fonts/Noto_Sans_KR/NotoSansKR-Black.otf') format('opentype');
}

html {
    min-height: 100%;
    cursor: default;
}

body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Noto Sans KR';
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: #212529;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: keep-all;
    word-wrap: break-word;

    /*아이폰 이미지 저장 방지*/
    -webkit-touch-callout: none;
}
